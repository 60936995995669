<template>
  <div>
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
    <Suspension/>
  </div>
</template>

<script>
import Header from "@/components/header.vue";
import Footer from "@/components/footer.vue";
import Suspension from "@/components/suspension.vue";

export default {
  components: {
    Header,
    Footer,
    Suspension,
  },
  data() {
    return {};
  },
  methods: {},
  created() {},
  mounted() {
   
  },
};
</script>

<style lang='scss'>
@import "@/assets/style/main.scss";

</style>
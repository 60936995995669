<template>
  <div class="suspension" @click="openPdf">
    <img
      :src="pdfData.pdf_button_image"
      alt="点击打开pdf"
      title="点击打开pdf"
      class="img"
    />
  </div>
</template>

<script>
import {getpdf} from "@/api/index"
export default {
  name: "Suspension",
  data() {
    return {
     pdfData:{},
    };
  },
  methods: {
    openPdf(){
        this.$router.push({ path: '/suspension' });
    },
  },
  mounted() {
    getpdf().then((res) => {
      this.pdfData = res.data;
    });
  },
};
</script>

<style scoped>
.suspension {
  z-index: 99999;
  width: 100px;
  height: 100px;
  position: fixed;
  right: 80px;
  bottom: 240px;
  border-radius: 15px;
  overflow: hidden;
  img{
    width: 100%;
  }
}
.img{
  width: 100%;
}
</style>

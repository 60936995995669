import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter);

// 保存原来的push函数
const originalPush = VueRouter.prototype.push;
// 重写push函数
VueRouter.prototype.push = function push(location) {
	// return originalPush.call(this, location).catch(err => err);

	// 这个if语句在跳转相同路径的时候，在路径末尾添加新参数（一些随机数字）
	// 用来触发watch
	if (typeof (location) == "string") {
		var Separator = "&";
		if (location.indexOf('?') == -1) { Separator = '?'; }
		location = location + Separator + "random=" + Math.random();
	}

	// 这个语句用来解决报错
	// 调用原来的push函数，并捕获异常
	return originalPush.call(this, location).catch(error => error);
};

const router = new VueRouter({
	mode: 'history', 
	routes: [{
		path: "/",
		redirect: "/home"
	},
	{
		path: "/home",
		name: "首页",
		component: () => import('../views/home/home.vue')
	},
	{
		path: "/product",
		name: "产品中心",
		component: () => import('../views/product/product.vue')
	},
	{
		path: "/productDetail",
		name: "产品详情",
		component: () => import('../views/product/productDetail.vue')
	},
	{
		path: "/aboutmine",
		name: "关于我们",
		component: () => import('../views/aboutmine/aboutmine.vue')
	},
	{
		path: "/contactus",
		name: "联系我们",
		component: () => import('../views/contactus/contactus.vue')
	},
	{
		path: "/panorama",
		component: () => import('../views/panorama/panorama.vue')
	},
	{
		path: "/newsCenter",
		name: "新闻中心",
		component: () => import('../views/newsCenter/newsCenter.vue')
	},
	{
		path: "/newsDetail",
		name: "新闻详情",
		component: () => import('../views/newsCenter/newsDetail.vue')
	},
	{
		path: "/askprice",
		name: "询价记录",
		component: () => import('../views/askprice/askprice.vue')
	},
	{
		path: "/suspension",
		name: "pdf",
		component: () => import('../views/suspension/suspension.vue')
	},
	{
		path: "/login",
		component: () => import('../views/login/login.vue')
	},
	]
})

export default router;